import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'humanReadable'})
export class HumanReadablePipe implements PipeTransform {
    private static capitalize(word: string): string {
        return word.charAt(0).toUpperCase() + word.substring(1);
    }

    transform(input: string): string {
        return HumanReadablePipe.transform(input);
    }

    static transform(input: string): string {
        if (input?.length) {
            const words = input.match(/[A-Za-z][a-z]*/g);

            return words.map(word => HumanReadablePipe.capitalize(word)).join(' ');
        }
        return input;
    }
}
